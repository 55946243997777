<template>
  <div :class="{ whiteListPage: showWhiteListPage }">
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content}` : `MintMade` }}</template>
    </metainfo>
    <Header
      :connected="$store.getters.getWallet.wallet.connected"
      :massMediaSettings="massMediaSettings"
      :menuContent="false"
      :showMyCollection="true"
      :blackTheme="showWhiteListPage"
      :hideMenu="showWhiteListPage"
      :hideUser="showWhiteListPage"
      :variant="showWhiteListPage ? 'ct-dark' : ''"
    />

    <!-- <marquee-text :repeat="10" :duration="30" class="marqueHoliday" v-if="$store.getters.getIsTop100Page">
      <div class="marqueHoliday__item">
        ALL PROCEEDS WILL BE TRANSFERED TO SUPPORT EARTHQUAKE VICTIMS IN TURKEY AND SIRIA
      </div>
      <div class="marqueHoliday__item">
        MINT WILL STOP ON MARCH 27, 12:00 UTC
      </div>
    </marquee-text> -->

    <!-- <marquee-text :repeat="10" :duration="30" class="marqueHoliday">
      <div class="marqueHoliday__item">
        <div class="marqueHoliday__images">
          <img :src="require('@/assets/images/holiday/marqueHoliday1.png')" alt="">
          <img :src="require('@/assets/images/holiday/marqueHoliday2.png')" alt="">
          <img :src="require('@/assets/images/holiday/marqueHoliday3.png')" alt="">
        </div>
        Holiday Special trait for all articles published from 23/12/22 to 9/01/23
      </div>
    </marquee-text> -->

    <!--<NftWhiteList v-if="showWhiteListPage" />
    <router-view v-else />-->
    <router-view></router-view>

    <Preloader
      style="display: block"
      v-if="!$store.getters.getPageLoaded"
      :style="[$store.getters.getPageLoaded ? { display: 'none' } : { display: 'block' }]"
      :image="require('@/assets/images/icons/preloader.svg')"
    />

    <Preloader
      style="display: block; position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 10000"
      v-if="$store.getters.getPreloaderShowed"
      :image="require('@/assets/images/icons/preloader.svg')"
    />

    <Footer v-if="$store.getters.getPageLoaded" :massMediaSettings="massMediaSettings" :variant="showWhiteListPage ? 'ct-dark' : ''">
      <template v-slot:customFooterCopyright>
        <a href="https://mintmade.io/" class="footer__copyright_logo" target="_blank"><img class="footer__copyright_logo_image" :src="require('@/assets/images/powered-by.svg')" alt="Mintmade" /></a>
      </template>
    </Footer>

    <!-- Choose wallet popup -->
    <ChooseWallet :isActive="$store.getters.getIsChooseWalletActive" @onClose="closeWalletChoose" @onWalletChoose="handleWalletChoose" />
    <!-- Choose wallet popup END -->
  </div>
</template>

<style lang="scss">
@import "./assets/css/index.scss";
@import "mintmade-main/src/assets/css/index.scss";

.marqueHoliday {
  background-color: $base-color;
  padding: 6px 0;

  .marquee-text-text {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    margin-right: 135px;
    font-weight: 600;
    -webkit-text-size-adjust: none;
  }

  &__images {
    display: flex;
    align-items: center;
    margin-right: 56px;
    img {
      max-height: 20px;
      margin-right: 6px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>

<script>
import massMediaSettings from "@/assets/json/massMedia/settings.json";
//import menuContent from '@/assets/json/menu/menu.json';
//import NftWhiteList from '@/views/NftWaitlist.vue';
import { Preloader, Transactions } from "mintmade-main";
import { useStore } from "vuex";
import amplitude from "amplitude-js";
import { ChooseWallet, Header, Footer } from "mintmade-main";
//import MarqueeText from 'vue-marquee-text-component'
//import axios from 'axios';
//import { useToast } from "vue-toastification";

export default {
  name: "App",
  data: () => ({
    massMediaSettings,
    //menuContent,
    showWhiteListPage: false,
    userChecked: false,
    toastShowed: false,
    checkStarted: false,
  }),
  setup() {
    amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_KEY, null, {
      includeUtm: true,
    });
    window.amplitude = amplitude;

    const store = useStore();
    store.dispatch("createWallet", {
      payments: ["card", "matic", "mmt"],
      hideToast: true,
    });
  },
  methods: {
    closeWalletChoose() {
      this.$store.commit("SET_CHOOSE_WALLET_ACTIVE", false);
    },
    async handleWalletChoose({ chooseWallet }) {
      this.$store.commit("SET_CHOOSE_WALLET_ACTIVE", false);
      const wallet = await this.$store.getters.getWallet;
      // const openedFromBuy = await this.$store.getters.getOpenedFromBuy;

      if (chooseWallet == "metamask") {
        window.amplitude.getInstance().logEvent("login with metamask");
        wallet.metamaskConnect(false, this.showWhiteListPage);
      }

      if (chooseWallet == "walletconnect") {
        window.amplitude.getInstance().logEvent("login with walletconnect");
        wallet.walletConnect(false, this.showWhiteListPage);
      }

      if (chooseWallet == "magic") {
        window.amplitude.getInstance().logEvent("login with magic link");
        wallet.magicConnect(false, this.showWhiteListPage, this.$store);
      }
    },
    appHeight() {
      document.documentElement.style.setProperty("--app-height", window.innerHeight + "px");
    },
    hidePreloder() {
      var preloader = document.getElementsByClassName("pagePreloader")[0];
      preloader.style.display = "none";
    },
    async checkAllTransactions() {
      if (this.$store.getters.getWallet) {
        var transactions = Transactions.getAllTransaction();
        for (var token_id in transactions) {
          var transactionHash = transactions[token_id];

          var status = await this.$store.getters.getWallet.checkTransaction(transactionHash);

          if (status == "success" || status == "error") {
            Transactions.deleteTransactionByTokenId(token_id);
          }
        }
      }
    },
    async checkUserWhitelist() {
      this.hidePreloder();
      /*const $this = this;

        if(!this.checkStarted){
          $this.checkStarted = true
          setTimeout(function(){
            $this.checkStarted = false
          }, 1);

          if(this.$store.getters.getWallet.wallet.connected && this.$store.getters.getWallet.wallet.address){
            var inWhiteList = false;
            var inOwnerList = false;

            try{
              await axios.get(process.env.VUE_APP_API_DAPI_URL + "/waiters/" + this.$store.getters.getWallet.wallet.address).then(res => {
                if(res.data && $this.massMediaSettings.openedWhitelist){
                  inWhiteList = true
                }
              });
            } catch {
              inWhiteList = false
            }

            if(!inWhiteList){
              try{
                await axios.get(process.env.VUE_APP_API_DAPI_URL + "/managers/" + this.$store.getters.getWallet.wallet.address).then(res => {
                  if(res.data){
                    inOwnerList = true
                  }
                });
              } catch {
                inOwnerList = false
              }
            }

            this.showWhiteListPage = !(inOwnerList || inWhiteList)

            if(this.showWhiteListPage){
              this.$store.getters.getWallet.disconnect(this.showWhiteListPage)

              if(!this.toastShowed){
                this.toastShowed = true
                const toast = useToast();
                toast.error("Sorry, you are not allowed to access yet", {
                    timeout: 2000
                });
                setTimeout(function(){
                  $this.toastShowed = false
                }, 100);
              }
            } else {
              const toast = useToast();
              toast.success("Wallet connected", {
                  timeout: 2000
              });
            }

            this.hidePreloder();
          } else if(this.$store.getters.getWallet.wallet.connected !== null) {
            this.showWhiteListPage = true
            this.hidePreloder();
          }
        } */
    },
  },
  watch: {
    "$store.getters.getWallet.wallet.address"() {
      this.checkUserWhitelist();
      this.checkAllTransactions();
    },
    "$store.getters.getWallet.wallet.connected"() {
      this.checkUserWhitelist();
      this.checkAllTransactions();
    },
  },
  components: {
    Header,
    Footer,
    Preloader,
    ChooseWallet,
    //NftWhiteList,
    //MarqueeText
  },
  mounted() {
    this.appHeight();
    this.checkUserWhitelist();
    this.$store.commit("UPDATE_NFT_NEW_COUNT");
    window.addEventListener("resize", this.appHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.appHeight);
  },
};
</script>
